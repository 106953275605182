export const eventsData = [
    {
        title: "TECHNICAL EVENTS",
        id: "technical_events",
        subtitle: "DREAM IT. MAKE IT.",
        description: "Ready to unravel the tech giant within you? We have the best in store for you. Starting from hackathons to fun contests to tech talks, let's discover your innovative side! Think you are the best? Well you can make a statement here!!!",
        events: [
            {
                date: "2ND & 3RD MARCH",
                time: "4 PM - 5 PM (Next day)",
                description: "Can you imagine a Tech-fest without a Hackathon? Obviously not. So, here we bring in our hackathon which is going to be the flagship tech event of Advaita- Hackfest 23, a software and hardware hackathon which is open to all. Hackfest-Advaita will begin with its first round of idea submission from 23rd to 27th Jan on the platform of Devpost. The second round will be held offline at the IIIT-BH campus on 16th and 17th of Feb.",
                image: "/event-images/intra-hackathon.webp",
                link: "https://hackfest-advaita.devpost.com",
                linkText: "Visit Page",
                customPopUp: "Round 1 selections for the Hackathon is already over. You can look around the hackathon page, but new registrations are not allowed.",
                prize: 244000,
            },
            {
                date: "2ND - 5TH MARCH",
                time: "10:00 AM - 6:00 PM",
                description: "Learn the art of flying drones and turn your passion into a profession. TARS-IIIT-BH in collaboration with Inside-FPV is conducting FLY THROUGH: Drone Flying Experience and Training Session. So, do not miss the golden opportunity to get yourself trained by approved drone trainers and make a career in the rapidly growing drone industry.",
                image: "/event-images/fly-through.webp",
                prize: 30000,
            },
            {
                date: "3RD MARCH",
                time: "10:00 AM - 1:00 PM",
                description: "Coding is great but penalties are not and moreover when it’s bitter or worse. Couldn’t understand? Well, this fun event will test your skills through guts! It is an interesting fun coding competition which is formatted into two rounds. The first online round will select the top 50 coders who will compete in different groups. Each group will get a single question and failing to complete it within the given decreasing time limit will result in a penalty. The penalty will be in the form of a not-so-good drink. So, code your way out within time to escape the penalty of a dreadful drink.",
                image: "/event-images/swig-n-code.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfL5nEci0igHBr8sluN5VJhIHwFu1LUIhwZSRcXojQlx8K5OQ/viewform",
                linkText: "Register",
                prize: 20000,
                // ruleBook: "https://hackfest-advaita.devpost.com",
            },
            {
                date: "3RD MARCH",
                time: "9:00 AM - 1:00 PM",
                description: "Robots are taking over a lot of our departments. We can sit and relax as they do our work. Signs of concern perhaps, but not in this case. \"Robo race\" is an event where not only your robot but also you have to do the work. The participants have to design a robot either wired or wireless within specified dimensions that operates manually and can traverse through all turns of the track. They race against time and other competitors in an attempt to emerge as the ultimate robo racer.",
                image: "/event-images/robot-race.webp",
                link: "https://docs.google.com/forms/u/1/d/e/1FAIpQLSfrCcO3bQNHser7PLMFlisYtX8uAIW6c1aFvk0TpPdTaw4nhQ/viewform",
                linkText: "Register",
                prize: 20000,
            },
            {
                date: "3RD MARCH",
                time: "3:00 PM - 4:00 PM",
                description: "The world around us is evolving rapidly and its best for us to evolve as well. With new tools and technologies surfacing every now and then, it’s a must for any person to know about them. Sounds quite daunting, doesn't it? Well, you won't be alone on this journey. We bring to you, something very different from your daily classroom lectures, a two hour long hands on workshop on the new technologies by tech experts where you can learn: - - open source - cracking best internships - grab more opportunities - insights on college life and further",
                image: "/event-images/tech-workshop.webp"
            },
            {
                date: "4TH MARCH",
                time: "9:00 AM - 5:00 PM",
                description: "Remember the golden old days of science exhibition in school? Well, we’re bringing back that nostalgia through this event! This amazing event is going to be a day long exhibition of the most interesting and useful projects contributed by the students and community.",
                image: "/event-images/tech-nova.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSeQfQwfrXd-gvYhcD9SV7MOL79nBkfcZvheg_rVNZUTAzeAFg/viewform",
                linkText: "Register",
                prize: 20000,
            },
            {
                date: "4TH MARCH",
                time: "10:00 AM - 2:00 PM",
                description: "Guidance is the biggest weapon that helps us fight our way through success. How amazing does it feel when experienced Tech leads guide us? Well, this is obviously going to be an insightful seminar or talk related to new technologies by a popular speaker or community person. This is going to be a promising and engaging session for all the tech enthusiasts. So, come on and gain new insights and perspectives on the future of technology by the famous speaker.",
                image: "/event-images/tech-talk.webp"
            },
            {
                date: "4TH MARCH",
                time: "10:00 AM - 1:00 PM",
                description: "Drone racing is a fast-growing sport that combines technology, skill, and excitement, making it an incredible experience for both participants and onlookers. Pilots race small, agile drones through an obstacle course at high speeds which is typically set up with gates, hoops, and other obstacles that challenge the pilots' precision and control as they maneuver through the course.",
                image: "/event-images/drone-racing.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLScv1o9HeMcCqjBvDbYKTwHjRRCCKKLyEEjAbsltJ7ef96BN6g/viewform",
                linkText: "Register",
                prize: 30000,
            },
            {
                date: "5TH MARCH",
                time: "9:00 AM - 1:00 PM",
                description: "Ever went on hunting? Nope it’s not a normal hunt, here you need to hunt down the hackers instead of the animals! This thrilling event is a proper red teaming event where the participants will emulate real life black hat hackers and understand their tactics and techniques.",
                image: "/event-images/cyber-hunt.webp",
                link: "https://surveyheart.com/form/63fb67a693973e26c42ab51a",
                linkText: "Register",
                prize: 20000,
            },
            {
                date: "5TH MARCH",
                time: "10:00 AM - 1:00 PM",
                description: "Who doesn't love car races- a tournaments where the drivers showcase their skills and their cars as they race against each other through multiple obstacles and difficult tracks. Sounds amazing doesn't it? Well, you can be a part of it too. We bring to you the \"Dirt Rush\" a form of Remote-Controlled car racing that takes place on dirt tracks where you have to navigate through obstacles such as jumps, berms, and whoops in the least time possible.",
                image: "/event-images/dirt-rush.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfONNLpZDjhV9QNTfnUgFlVN7c8sdljK8wCT0jyQK9bIEOPLQ/viewform",
                linkText: "Register",
                prize: 30000,
            },
            {
                date: "5TH MARCH",
                time: "2:30 PM - 5:00 PM",
                description: "You must be thinking \"Well here's another one of those robo events\", don't be so quick to judge this one is entirely different.Remember the childhood games when we had to follow some lines and patterns while walking.This event helps you relive that very moment of your childhood with a small twist: This time it's not you but your robot \"the line follower robot\" who has to follow the line. The line follower robot is an autonomous mobile machine that can detect and follow a black line or a strip made on the floor within a specified margin of error. The robots compete against time and the one finishing in the least time wins.",
                image: "/event-images/line-follower.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdYzPlMsEFD3nkqmzZmTl2W6UBFFOdnU3eUD7mkK5A_gpZrPQ/viewform",
                linkText: "Register",
                prize: 21000,
            },
            {
                date: "4TH MARCH",
                time: "2:30 PM - 5:00 PM",
                description: "Ever made a team and worked individually? Well, this event will test your code as well as team compatibility. It is a coding contest with a single coding round for teams made of two. ICPC format of contest will be followed with some different rules. Both the team members will solve the same question but not together, they will solve the question individually and after a time limit, they will switch their positions and continue till the time reaches for that particular question. So, work on the live problem in a disjointed team of two and switch your positions after half the total time.",
                image: "/event-images/switch-code.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSc7m8LFP0_OmGBz_Ux_tTDJ_tUlJwcog8nE40L9PnnA9zH-AQ/viewform",
                linkText: "Register",
                prize: 20000,
            },
        ]
    },
    {
        title: "CULTURAL EVENTS",
        id: "cultural_events",
        subtitle: "VIBE YOUR SOUL AWAY",
        description: "Hey singers and dancers! Here's your chance to enchant the whole arena with your charming voice or set the stage on fire with your dazzling dance steps as you participate and compete with the best.",
        events: [
            {
                date: "3RD & 5TH MARCH",
                time: "9:00 AM - 1:00 PM",
                description: "Letting yourself lose to the beats is the best flow of dance. Footloose has seen dancers of all styles come and perform their heart out on the stage. And as a matter of fact this is one of the most amusing competitions of Advaita.",
                image: "/event-images/footloose.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSficxBodOsUFnOrS4cPU2xkcYAfhKSEjBdeybbsLmaUchCaLw/viewform",
                linkText: "Register",
            },
            {
                date: "3RD & 5TH MARCH",
                time: "12 - 4 PM (3rd), 9 - 1 PM (5th)",
                description: "Championing the unusual, unorthodox and unheard, our annual Battle of the Bands competition is back. Whether you’re looking to showcase new adventures into electronica or dip your toe into experimental waters, ROCKATHON, a resplendent musical band event is a chance for soloists, ensembles, improvisers and performance artists to showcase their talents. We invite musical bands – who chime with our ethos to perform and compete among themselves in an informal setting in front of a legion to emerge as the best one. So get the band together and take on the world in this battle of bands.",
                image: "/event-images/rockathon.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLScr8RhJK6PKIt7ymbZFRmqmGla9sfEHLGiaiscHIFDuDyY3nw/viewform",
                linkText: "Register",
            },
            {
                date: "4TH MARCH",
                time: "10:00 AM - 12:00 PM",
                description: "A mic is just a mic until something strong unsaid has been said aloud..and that..is when the mic is dropped. This event has two categories rapping and beatboxing. An intense rap battle where rappers shout their heart out to the beat and the best one drops the mic and gets the audience to go boom. Get to showcase your beatboxing skills and blast the stage with drums and crazy musical effects.",
                image: "/event-images/mic-drop.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLScD9M54Tq54OaTXOiExNHeQ6hALDd1rgM_RodgZI9htFm5Hcw/viewform",
                linkText: "Register",
            },
            {
                date: "4TH MARCH",
                time: "4:30 PM - 7:30 PM",
                description: "A statement can be ordinary but a fashion statement made strongly can turn heads. Fashion is the medium of expression and the team that best represents the theme wins hearts.",
                image: "/event-images/lamode.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfHPa_GD5FHIm5ZEF0CGHAriH4jT-qat8AO1VEbK6fzkAM_zA/viewform",
                linkText: "Register",
                prize: 30000,
            },
            {
                date: "5TH MARCH",
                time: "11:00 AM - 3:00 PM",
                description: "They say that you can touch a soul with a melody and Acoustica is where the souls come for their rhythm of life. A number of amazing singers compete together and you'd really wish it continues on and on and on.",
                image: "/event-images/acoustica.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfiCAB_rSusS1gTc4F7Jj56gzxr0Xje0vGVDefLVF16jTjEZQ/viewform",
                linkText: "Register",
            },
            {
                date: "5TH MARCH",
                time: "3:30 PM - 7:00 PM",
                description: "Cypher, as is the vibe of the name, it is a bang on street style dance competition with dancers across a number of universities joining to boast their raw moves on a single battleground and creating the authentic artist feels. This, my friends, is a sight to behold and an even better experience to enjoy.",
                image: "/event-images/cypher.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSeOAOFZaxlOdo4iTCirVRLarrh-E92sXyKHrTR10YSnH7S4Og/viewform",
                linkText: "Register",
                prize: 15000,
                extraInfo: "If you wish to just participate in Cypher during Advaita, you can purchase an event pass on the spot for ₹100 instead of the 1 or 4 day passes.",
            },
        ]
    },
    {
        title: "FILM AND THEATRE LITFEST",
        id: "litfest_events",
        subtitle: "FEEL TO EXPRESS",
        description: "Haven't had enough chances to show off your acting skills? Scared that people might judge you? Don't have enough confidence? Don't worry we will set the stage for you but the main question is ARE YOU READY to bring out the \"kalakar\" ?",
        events: [
            {
                date: "3RD MARCH",
                time: "7:00 PM - 10:00 PM",
                description: "Rangbhumi - where inner feelings will be expressed through art, culture and visual arts. A place where the audience is transported to a whole new world and becomes a part of the story that unfolds on stage. So evoke the actor inside you because every scene you will ever act in begins in the middle, and it is up to you, the actor, to provide what comes before. Put on your acting hats and bring what's in your mind into reality. Become the one you have dreamed of and turn all impossibilities into possibilities. Come together to experience something truly magical.",
                image: "/event-images/rangbhumi.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSd1ZpCPB94H4q2aanXlbqZQHAw8oj_6tBp5z1bJtMN9sNOLfA/viewform",
                linkText: "Register",
                prize: 25000,
            },
            {
                date: "4TH MARCH",
                time: "9:00 AM - 1:00 PM",
                description: "Change! Reform! Badlaav! Nukkad is a street corner gathering where people come together to discuss social, cultural, and political issues. These events often involve performances such as plays, skits, and music, and can be used to raise awareness about important issues or to bring a community together. Nukkad events are a great way to engage with your community and make a positive impact on the issues that matter to you. Experience the power of collective voices as we come together to raise awareness and bring about positive change.",
                image: "/event-images/nukkad-show.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSeTKpj30Ff8gac3Ay34K78U2MHnBXaz4fKVqPrfe7kANP9azg/viewform",
                linkText: "Register",
            },
            {
                date: "5TH MARCH",
                time: "2:00 PM - 4:00 PM",
                description: "“Bringing the best out of you, one stage at a time.” Whether you're an experienced thespian or just starting out, this is your chance to shine and impress a panel of pros. From monologues to scenes and short plays, this talent show will give you the opportunity to showcase your range and versatility as an actor. Participants will compete for cash prizes and the chance to discover your hidden talent. ‘Mai Kalakaar’ is not just about showcasing talent, it's about fostering a community of actors and encouraging them to grow and evolve. ",
                image: "/event-images/mai-kalakar.webp",
                prize: 10000,
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdhn1xtDwSbQ8jC_Fj3tcFmrBRxsLPTQn6JobYfKg2MFuKrLg/viewform",
                linkText: "Register",
            },
        ]
    },
    {
        title: "ART",
        id: "art_events",
        subtitle: "DRAWING THE DESIRE",
        description: "Artist? Not an artist? Well it doesn't really matter. They say art lies all around us . Well we don't deny and nor would you after being a part of the plethora of amazing events we bring to you.",
        events: [
            {
                date: "3RD MARCH",
                time: "2:00 PM - 4:00 PM",
                description: "How accurately can you make art? An art testing skill competition \"ART-CURRACY\" which will test your art skills and your descriptive abilities. Only good words are not enough, being able to draw accurate things is also a challenging part of itself. It will be fun and thrilling to witness this competition. Also for those artistic friends, it gives you a platform to showcase and test your art skills. The participants will compete in teams of two where one of them will be given a picture that he/she will see and describe to the other, and the other participant needs to draw it accurately based on the description provided.",
                image: "/event-images/art-curacy.webp",
                prize: 5000,
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdVEV8x7-lMT3iE0vcof8-9lJpVFCC5SKtrzHZg0Z8c3KUp0Q/viewform",
                linkText: "Register",
            },
            {
                date: "3RD MARCH",
                time: "4:30 PM - 6:30 PM",
                description: "We don't make mistakes, we have happy accidents. ~Bob Ross Do you love the process of making art and get engrossed in the realm of paintings? Making art is no less than magic. It is one of those creations that allows for interpretation of any kind. We have all of that in stock for you to showcase your talent but with a TWIST. ADVAITA presents \"PICASSO\", a relay race in which you and your teammates will make use of the paint brushes to add paint strokes one by one to make a meaningful work of art. Join us in this battle of creativity and show off your artistic prowess to the world.",
                image: "/event-images/picasso.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdj4Cs6PSd37XOvcRCz0vkVVofj7xc82pPQYyapAT_ivWD8dw/viewform",
                linkText: "Register",
                prize: 5000,
            },
            {
                date: "5TH MARCH",
                time: "2:00 PM - 5:00 PM",
                description: "Treasure hunting has always been an activity of adrenaline, intelligence and bravery. People have been searching for gold and precious stones in the form of treasure for centuries. But what if we introduce a twist by bringing in artistic treasure and creating an universe of creative brilliance? ADVAITA brings to you \"TREAVENGER HUNT\". Within a set amount of time, locate the hidden art supplies and use them to create your masterpiece. Set your heart ablaze and go beyond your limits during the search and win the battle with all the tools at your disposal.",
                image: "/event-images/treavenger-hunt.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfU711OwBbT_IKXrtsCeMX6fwi7gv59djw5berz7Qeevx64bA/viewform",
                linkText: "Register",
                prize: 5000,
            },
            {
                date: "5TH MARCH",
                time: "5:00 PM - 7:00 PM",
                description: "No, it's not a drawing competition, but a game like Chinese Whisper. Yes, Chinese Whisper, it was fun - Shall we take it to the Next level and double the fun and excitement! Join us in this fun and creative event where we will be drawing on each other's backs in teams of 3, wherein the Chinese whisper is replaced with a pen and paper. The first person has to draw a piece of any art on the back of the other person and that person is required to draw the art that he is perceiving, passing on to the third one. Just make it as right as possible.",
                image: "/event-images/draw-it-right.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSeIkxVrG16AqD10drTXIHT1u7g0HeitS5o8oHxa2Slpe8OxsA/viewform",
                linkText: "Register",
                prize: 5000,
            },
        ]
    },
    {
        title: "GAMES",
        id: "game_events",
        subtitle: "STEP YOUR GAME UP",
        description: "Hola gamers and fun seekers!!! How can we start the party without ya all. We are officially sending the invitation to you for the ultimate battles. Let's see if you have what it takes to win. May the best say GG!",
        events: [
            {
                date: "XTH MARCH",
                time: "XX:00 AM",
                description: "There would be tournaments in popular games like Valorant PC, COD mobile etc. Also there would be a wide range of tournaments and leagues for all skill levels. Whether you’re a beginner or a pro, you can find the perfect competition to challenge yourself and show off your skills. Not to mention there would be a variety of rewards and prizes for the top players.",
                image: "/event-images/esports.webp"
            },
        ]
    },
    {
        title: "LITERATURE",
        id: "literature_events",
        subtitle: "WORDS THAT IGNITE",
        description: "The air is alive with the aroma of freshly printed pages and the buzz of excitement as book lovers from all walks of life gather to celebrate the written word. At this literature fest, imagination runs wild, creativity knows no bounds, and the power of storytelling reigns supreme.",
        events: [
            {
                date: "3RD MARCH",
                time: "5:00 PM - 6:30 PM",
                description: "Hola actors, we hope you are prepared for this one. All you need to do is immitate someone. Easy right? Well just a small twist. You have to imitate \"someone\" but the someone will be determined by throwing darts and then unscrambling whatever you get under it.",
                image: "/event-images/scrambling-dart.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSewq-IguiTeLH5qtS1HVvFq5khnUp_zCTp4l6yLjbASP7yHGA/viewform",
                linkText: "Register",
            },
            {
                date: "4TH MARCH",
                time: "11:00 AM - 1:00 PM",
                description: "The mehfil of Advaita is going to be a lot more jaandaar as the News And Publication Society IIIT Bhubaneswar brings to you a workshop by none other than Miss Vanika Sangtani. So folks get ready on 4th March from 11 AM to 1 PM, and be a part of our mehfil to make it even more memorable. For better or for verse!",
                image: "/event-images/vanika-workshop.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLScHSmzOcM1BvsmGo4dw4NixZJp-vfjcKzVJuLcth181EUMG2Q/viewform",
                linkText: "Register",
            },
            {
                date: "5TH MARCH",
                time: "3:30 PM - 5:30 PM",
                description: "Someone said sports and books are not related. Well we don't agree much on that and thats why we introduced the BBDM. An event where you get to flaunt how good you are at basket ball but only if you can prove your mettle of being a bookworm. The event is the perfect test of the athelete as well as the scholar in you.",
                image: "/event-images/bbdm.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfufw5oL-PwwIGRmr8f-rSloM-gqBnj7Tq9JqzafmDorgqknA/viewform",
                linkText: "Register",
            },
        ]
    },
    {
        title: "PHOTO GRAPHY",
        id: "photography_events",
        subtitle: "HEART OF A PERFECT CLICK",
        description: "Get ready to snap, shoot, and shine! Unleash your inner artist and your wildest imagination. Dive into the world of photography and learn the ins and outs of capturing stunning shots. Let's capture some unforgettable moments together!",
        events: [
            {
                date: "3RD MARCH",
                time: "9:00 AM - 11:00 AM",
                description: "Unleash your inner shutterbug and join us for a thrilling photography workshop! Led by renowned experts in the field, this workshop is a must-attend for anyone looking to up their photography game. Dive into the world of photography and learn the ins and outs of capturing stunning shots. From composition to lighting, this workshop will provide you with the tools and knowledge to take your photography to the next level. Don't miss out on this opportunity to learn from the best and become a master of the lens!",
                image: "/event-images/photography-workshop.webp"
            },
            {
                date: "2ND - 5TH MARCH",
                time: "9:00 AM - 9:00 PM",
                description: "Step into the world of photography and freeze your memories at A STILL IN TIME - the ultimate photo exhibition! Join us at #Advaita2023 and discover the beauty of capturing a moment in time through the lens. From stunning landscapes to candid shots, we've curated some of the best works from photographers of our college. Not only will you get to admire the art, but you'll also get the chance to snap your own memories at our photo booth. Don't miss out on this one-of-a-kind experience.",
                image: "/event-images/a-still-in-time.webp"
            },
            {
                date: "4TH MARCH",
                time: "2:00 PM - 5:00 PM",
                description: "Get ready to snap, shoot, and shine with IMAGEN—the ultimate destination for photography and videography enthusiasts! Whether you're a seasoned pro or just starting out, our specially curated competitions will inspire and challenge you to bring your creative vision to life. With IMAGEN, the world is your canvas and the camera is your brush—so let's capture some unforgettable moments together!",
                image: "/event-images/imagen.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdBFdU5hbU_8RHjZE6al1TUhmbMyVMPAfzMrEbX1r5Sk9WKNw/viewform",
                linkText: "Register",
                prize: 30000,
            },
        ]
    },
    {
        title: "FOOD",
        id: "food_events",
        subtitle: "SAVOR THE TASTE",
        description: "Any foodies here? Well we do hope so coz we have a lot amazing flavours in our hands where you not only get to cook exotic delicacies but also get to flaunt your eating skills.",
        events: [
            {
                date: "4TH MARCH",
                time: "9:00 AM - 1:00 PM",
                description: "Get ready for a Maggie-making extravaganza!!! Cook Maggie with a delicious punch. The competition will be a test of skill, creativity, and speed as participants race against the clock to create the most delicious and visually stunning Maggie. The rules are simple: each chef must use only the ingredients provided and complete their dish within the allotted time. The judges will be looking for originality, taste, and presentation. The stakes are high, as the winner will receive bragging rights as the ultimate Maggie master. Don't miss out on this Maggie-making action!",
                image: "/event-images/maggie-chef.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdO0LpaJvGvQvU9WTquUxDGGAo-0RjnTGDQclOMVlEcGDyYdg/viewform",
                linkText: "Register",
                prize: 2000,
            },
            {
                date: "4TH MARCH",
                time: "3:00 PM - 5:00 PM",
                description: "Get ready for a mouth-watering, gastronomic adventure! The Golgappa Eating Competition is here, and it's sure to be a blast. Contestants will have to devour as many crispy and tangy golgappas as they can within a set time limit. These golgappas will be filled with a variety of fillings such as potato, chana, and tamarind chutney. This event is open to all the foodies with diverse tastes, and the winner will be the one who eats the most golgappas in the given time limit and is awarded the title of 'Golgappa King/Queen' along with a prize.",
                image: "/event-images/golgappa-king.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLScPx8OP__L4TmW3XltkXoCF5aTFD00aGp5vC-2KeR1tleY96w/viewform",
                linkText: "Register",
                prize: 2000,
            },
            {
                date: "5TH MARCH",
                time: "10:00 AM - 12:00 PM",
                description: "Brace yourself for a fiery culinary challenge! The Parantha Eating Competition with Hot Chilli Sauce is back, and it's going to be hotter than ever. Contestants will have to chow down as many delicious, piping-hot paranthas smothered in fiery hot chili sauce as they can within a set time limit. The heat is on as participants compete to see who can handle the hottest sauce and eat the most paranthas. The winner will be the one who can eat the most paranthas in the given time limit and will be rewarded with a prize. Don't miss out on the thrilling action and the chance to taste some of the spiciest paranthas around. Mark your calendars and come join us to cheer on your favorite contestant and enjoy the delicious food.",
                image: "/event-images/shole-paranthe.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSfFDo1obJiwjmMMowGmR8nXCp3GjO2fXZQFAC71pn4fGEUaWA/viewform",
                linkText: "Register",
                prize: 2000,
            },
            {
                date: "5TH MARCH",
                time: "5:30 PM - 7:30 PM",
                description: "Prepare yourself for an unparalleled culinary experience! Introducing the stringed delicacy, a playful and exhilarating event that will tantalize your taste buds and bring some fun to your mealtime. Take a bite and let the flavors dance on your tongue as you savor the tender delicacies. The delicacies are expertly crafted and suspended from a string, creating a unique and visually appealing treat. The string adds a playful touch, making it a perfect snack for just a fun day with friends. Whether you're looking for a tasty appetizer or a fun and unique main course, the stringed delicacy is sure to be a hit. So go ahead, give it a try and get ready for a deliciously fun experience.",
                image: "/event-images/stringed-delicacy.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLScGGOxAj9ur_jifk0aEU0jNl1gjWJKDMqFaJOYjcYzmG-FP-w/viewform",
                linkText: "Register",
                prize: 2000,
            },
        ]
    },
    {
        title: "SOCIAL CONNECT",
        id: "social_connect",
        subtitle: "HELP FOR HOPE",
        description: "Connect, build relationships, and foster a sense of belongingness in a supportive and engaging atmosphere. Make new friends, find a support network, have some fun, help support important causes and make a positive impact in the community.",
        events: [
            {
                date: "2ND MARCH",
                time: "4:00 PM - 6:00 PM",
                description: "SPIC MACAY (Society for the Promotion of Indian Classical Music And Culture Amongst Youth) is a voluntary youth movement which promotes intangible aspects of Indian cultural heritage by promoting Indian classical music, classical dance, folk music, yoga, meditation, crafts and other aspects of Indian culture. Witness the performance of Sri Joydeep Mukherjee (Sarod, Mohanveena & Sursingar exponent, Sangeet Natak Academi Awardee, Regular Artist of All India Radio & Doordarshan, Empanelled Artiste of ICCR, New Delhi).",
                image: "/event-images/spicmacay.webp"
            },
            {
                date: "3RD MARCH",
                time: "10:00 AM - 1:00 PM",
                description: "As per swami vivekananda: Service to man is service to God. The truth of this adage also applies in case of blood donation. Saving lives by donating blood is no less meritorious than any act of God. Realizing this, a blood donation camp will be organised in the college campus to contribute to the urgent need for patients confronting trauma and other life-saving treatments, such as blood transfusions. It reduces the risk of heart attack and blood pressure in the donor while meeting the receiver's vital needs. As it is rightly said: Show an act of care, your Blood-Donation can keep one’s prayer.",
                image: "/event-images/blood-donation.webp",
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdufT4rEI6HnfZpKR_ix1E0k4PAgANnOEEspGtE0wLHMZEkvg/viewform",
                linkText: "Register",
            },
            {
                date: "3RD MARCH",
                time: "4:00 PM - 5:00 PM",
                description: "It is man's curiosity that widens the scope of his knowledge. But it is not possible to achieve everything on one's own in the short span of human life. As it is rightly said: Learn from the experts; you will not live long enough to figure it all out by yourself. Umang is where renowned and accomplished speakers enlighten the students and help them form a broader perspective about various aspects of life. The objective is to arouse enthusiasm and motivate students to step outside their comfort zone and feel confident. So don't miss this golden opportunity and satiate your curiosity appetite by getting plenty of knowledge.",
                image: "/event-images/umang.webp"
            },
            {
                date: "5TH MARCH",
                time: "5:00 AM - 10:00 AM",
                description: "Ready, set, run! Join us as we lace up our sneakers and hit the pavement for our society's marathon 🏃‍♀🏃‍♂ Let's go the distance together and make a difference! Vedanta Samiti is here with the marathon on 5th March 2023. So put your sports shoes on and  mark your calendar as we have some exciting times ahead.",
                image: "/event-images/marathon.webp",
                prize: 30000,
                link: "https://docs.google.com/forms/d/e/1FAIpQLSc6iFedBfwXfMvbhUDXOzlArycXRwzjhD4Tm7SNUei3NNgwuQ/viewform",
                linkText: "Register",
            },
        ]
    },
];
